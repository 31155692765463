package components

import ValueChangeEvent
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.json.JsonElement
import react.*
import react.dom.attrs
import schema.ConfigSchema
import schema.ConfigValueType
import schema.JsonType
import styled.*

external interface JsonArrayEntryProps: Props {
    var name: String
    var schema: ConfigSchema
    var data: JsonElement?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
    var onDelete: (id: String) -> Unit
    var level: Int
}

data class JsonArrayEntryState(
    var data: JsonElement?,
    var showChildren: Boolean = true,
): State

class JsonArrayEntry(props: JsonArrayEntryProps): RComponent<JsonArrayEntryProps, JsonArrayEntryState>() {

    init {
        this.state = JsonArrayEntryState(
            data = props.data,
        )
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                margin(0.px, 0.px, 10.px, 20.px)
                padding(5.px)
                display = Display.flex
                alignContent = Align.center
                alignItems = Align.center
            }

            if (props.schema.type != ConfigValueType.OBJECT)
                styledI {
                    css {
                        color = Color.red
                    }
                    attrs {
                        classes = setOf("ri-delete-bin-7-line ri-xl")
                        onClickFunction = {
                            props.onDelete(props.id)
                        }
                    }
                }

            styledDiv {
                css {
                    display = Display.inlineBlock
                    if (props.schema.type == ConfigValueType.OBJECT) {
                        borderColor = hsl((props.level * 40) % 256, 100, 15)
                        +Styles.objectContainer
                    }
                }

                if (props.schema.type == ConfigValueType.OBJECT)
                    styledDiv {
                        css {
                            +Styles.objectHeader
                        }
                        styledI {
                            attrs {
                                classes = if (state.showChildren) {
                                    setOf("ri-arrow-down-s-line", "ri-xl")
                                } else {
                                    setOf("ri-arrow-right-s-line", "ri-xl")
                                }

                                onClickFunction = {
                                    setState {
                                        showChildren = !showChildren
                                    }
                                }
                            }
                        }

                        styledI {
                            css {
                                color = Color.red
                            }
                            attrs {
                                classes = setOf("ri-delete-bin-7-line")
                                onClickFunction = {
                                    props.onDelete(props.id)
                                }
                            }
                        }
                    }

                if (state.showChildren)
                    jsonSchema(
                        schema = props.schema,
                        name = "",
                        data = state.data,
                        id = props.id,
                        handleChange = props.handleChange,
                        level = props.level,
                    )
            }
        }
    }
}

fun RBuilder.jsonArrayEntry(schema: ConfigSchema, data: JsonElement?, id: String, handleChange: (event: ValueChangeEvent) -> Unit, onDelete: (id: String) -> Unit, level: Int) {
    child(JsonArrayEntry::class) {
        attrs {
            this.schema = schema
            this.data = data
            this.id = id
            this.handleChange = handleChange
            this.onDelete = onDelete
            this.level = level
        }
    }
}