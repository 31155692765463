package components

import Styles
import kotlinx.html.classes
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.attrs
import styled.css
import styled.styledDiv
import styled.styledI
import styled.styledP

external interface DescriptionBlockProps: Props {
    var description: List<String>
}

class DescriptionBlock(props: DescriptionBlockProps): RComponent<DescriptionBlockProps, State>() {

    override fun RBuilder.render() {
        if (props.description.isEmpty())
            return

        styledDiv {
            css {
                +Styles.descriptionContainer
            }

            styledDiv {
                css {
                    +Styles.descriptionIconContainer
                }

                styledI {
                    attrs {
                        classes = setOf("ri-information-line", "ri-xl")
                    }
                }
            }

            styledDiv {
                css {
                    +Styles.descriptionText
                }

                for (line in props.description) {
                    styledP {
                        css {
                            +Styles.descriptionLine
                        }
                        +line
                    }
                }
            }
        }
    }

}

fun RBuilder.descriptionBlock(description: List<String>?) {
    child(DescriptionBlock::class) {
        attrs {
            this.description = description?: listOf()
        }
    }
}