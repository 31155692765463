package components

import ValueChangeEvent
import kotlinx.css.portal
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.contentOrNull
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import react.*
import react.dom.attrs
import react.dom.option
import schema.ConfigSchema
import schema.JsonType
import styled.*

external interface JsonStringProps: Props {
    var schema: ConfigSchema
    var name: String
    var data: JsonPrimitive?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
}

data class JsonStringState(
    var value: String = "",
    var data: JsonPrimitive?,
): State


class JsonString(props: JsonStringProps): RComponent<JsonStringProps, JsonStringState>() {
    init {
        val default: String = props.schema.default?.contentOrNull ?: ""

        this.state = JsonStringState(
            value = props.data?.content ?: default,
            data = props.data,
        )

        props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(state.value)))
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                +Styles.inputContainer
            }
            if (props.name != "") {
                styledLabel {
                    +props.name
                    attrs {
                        htmlFor = "${props.id}-input"
                    }
                }
            }


            if (!props.schema.constraints.enum.isNullOrEmpty()) {
                styledSelect {
                    for (opt in props.schema.constraints.enum!!) {
                        option("", opt)
                    }

                    attrs {
                        id = "${props.id}-input"
                        value = state.value
                        onChangeFunction = {
                            val value = (it.target as HTMLSelectElement).value
                            setState {
                                this.value = value
                                this.data = JsonPrimitive(value)
                            }

                            props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(value)))
                        }
                    }
                }
            } else {
                styledInput {
                    attrs {
                        id = "${props.id}-input"
                        type = InputType.text
                        value = state.value
                        onChangeFunction = {
                            val value = (it.target as HTMLInputElement).value
                            setState {
                                this.value = value
                            }

                            props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(value)))
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.jsonString(schema: ConfigSchema, name: String, data: JsonPrimitive?, id: String, handleChange: (event: ValueChangeEvent) -> Unit) {
    child(JsonString::class) {
        attrs {
            this.schema = schema
            this.name = name
            this.data = data
            this.id = id
            this.handleChange = handleChange
        }
    }
}