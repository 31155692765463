package components

import NameChangeEvent
import ValueChangeEvent
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import react.*
import react.dom.attrs
import react.dom.option
import schema.ConfigSchema
import styled.*
import kotlin.random.Random

external interface JsonMapEntryProps: Props {
    var name: String
    var schema: ConfigSchema
    var data: JsonElement?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
    var onNameChange: (event: NameChangeEvent) -> Unit
    var onDelete: (id: String) -> Unit
    var allowedKeys: List<List<String>>?
    var usePredefinedKeys: Boolean
    var level: Int
}

data class JsonMapEntryState(
    var data: JsonElement?,
    var showChildren: Boolean = true,
    var name: String,
): State

class JsonMapEntry(props: JsonMapEntryProps): RComponent<JsonMapEntryProps, JsonMapEntryState>() {

    init {
        this.state = JsonMapEntryState(
            data = props.data,
            name = props.name,
        )

        if (!props.allowedKeys.isNullOrEmpty() && props.name.isBlank()) {
            val name = props.allowedKeys?.getOrNull(0)?.getOrNull(0) ?: ""
            console.log("Defaulting name to $name")
            this.state.name = name

            props.onNameChange(NameChangeEvent(props.id, name))
        }
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                borderColor = hsl((props.level * 40) % 256, 100, 15)
                +Styles.objectContainer
            }

            styledDiv {
                css {
                    +Styles.objectHeader
                }
                styledI {
                    attrs {
                        classes = if (state.showChildren) {
                            setOf("ri-arrow-down-s-line", "ri-xl")
                        } else {
                            setOf("ri-arrow-right-s-line", "ri-xl")
                        }

                        onClickFunction = {
                            setState {
                                showChildren = !showChildren
                            }
                        }
                    }
                }

                if (props.usePredefinedKeys) {
                    styledSelect {
                        if (state.name.isNotBlank()){
                            option("", state.name)
                        }

                        for (optGroup in props.allowedKeys!!) {
                            for (opt in optGroup)
                                option("", opt)
                        }



                        attrs {
                            value = state.name
                            onChangeFunction = {
                                val value = (it.target as HTMLSelectElement).value
                                setState {
                                    this.name = value
                                }

                                props.onNameChange(NameChangeEvent(props.id, value))
                            }
                        }
                    }
                } else {
                    styledInput {
                        attrs {
                            type = InputType.text
                            value = state.name
                            onChangeFunction = {
                                val value = (it.target as HTMLInputElement).value
                                setState {
                                    this.name = value
                                }

                                props.onNameChange(NameChangeEvent(props.id, value))
                            }
                        }
                    }
                }

                styledI {
                    css {
                        color = Color.red
                        marginLeft = 10.px
                        alignSelf = Align.center
                    }
                    attrs {
                        classes = setOf("ri-delete-bin-7-line")
                        onClickFunction = {
                            props.onDelete(props.id)
                        }
                    }
                }
            }

            styledDiv {
                css {
                    if (!state.showChildren)
                        display = Display.none
                }
                jsonSchema(
                    schema = props.schema,
                    name = "",
                    data = state.data,
                    id = props.id,
                    handleChange = props.handleChange,
                    level = props.level
                )
            }
        }
    }
}

fun RBuilder.jsonMapEntry(schema: ConfigSchema, key: String, data: JsonElement?, id: String, handleChange: (event: ValueChangeEvent) -> Unit, onNameChange: (event: NameChangeEvent) -> Unit, onDelete: (id: String) -> Unit, allowedKeys: List<List<String>>?, usePredefinedKeys: Boolean, level: Int) {
    child(JsonMapEntry::class) {
        attrs {
            this.schema = schema
            this.name = key
            this.data = data
            this.id = id
            this.handleChange = handleChange
            this.onNameChange = onNameChange
            this.onDelete = onDelete
            this.allowedKeys = allowedKeys
            this.usePredefinedKeys = usePredefinedKeys
            this.level = level
        }
    }
}