package components

import ValueChangeEvent
import kotlinx.css.*
import kotlinx.css.properties.border
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.buildJsonArray
import react.*
import react.dom.attrs
import schema.ConfigSchema
import styled.*
import kotlin.random.Random

external interface JsonArrayProps: Props {
    var schema: ConfigSchema
    var name: String
    var data: kotlinx.serialization.json.JsonArray?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
    var level: Int
}

data class JsonArrayState(
    var entries: MutableMap<Int, ConfigSchema> = mutableMapOf(),
    var data: kotlinx.serialization.json.JsonArray?,
    var realData: MutableMap<Int, JsonElement> = mutableMapOf(),
    var showChildren: Boolean = true,
    var nextId: Int = 0,
): State


class JsonArray(props: JsonArrayProps): RComponent<JsonArrayProps, JsonArrayState>() {
    init {
        this.state = JsonArrayState(
            data = props.data,
        )

        props.data?.forEachIndexed { index, element ->
            state.entries[index] = props.schema.items!!
            state.realData[index] = element
        }
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                borderColor = hsl((props.level * 40) % 256, 100, 15)
                +Styles.objectContainer
            }
            styledDiv {
                css {
                    +Styles.objectHeader
                }
                styledI {
                    attrs {
                        classes = if (state.showChildren) {
                            setOf("ri-arrow-down-s-line", "ri-xl")
                        } else {
                            setOf("ri-arrow-right-s-line", "ri-xl")
                        }

                        onClickFunction = {
                            setState {
                                showChildren = !showChildren
                            }
                        }
                    }
                }
                //ri-delete-bin-7-line

                if (props.name != "") {
                    styledLabel {
                        css {
                            textTransform = TextTransform.capitalize
                        }
                        +props.name
                    }
                }

                styledI {
                    css {
                        color = hex(0x4caf50)
                        alignSelf = Align.center
                    }
                    attrs {
                        classes = setOf("ri-add-line", "ri-xl")
                        onClickFunction = {
                            setState {
                                entries[state.nextId] = props.schema.items!!
                                nextId++
                                showChildren = true
                            }
                        }
                    }
                }
            }
            styledDiv {
                css {
                    if (!state.showChildren)
                        display = Display.none
                }

                descriptionBlock(props.schema.description)

                state.entries.forEach {
                    jsonArrayEntry(
                        schema = it.value,
                        data = state.realData[it.key],
                        id = it.key.toString(),
                        handleChange = ::handleChange,
                        onDelete = ::handleDelete,
                        level = props.level + 1,
                    )
                }
            }
        }
    }

    fun handleChange(event: ValueChangeEvent) {
        state.realData[event.id.toInt()] = event.value
        state.data = buildJsonArray {
            state.realData.forEach {
                add(it.value)
            }
        }

        props.handleChange(ValueChangeEvent(props.id, state.data!!))
    }

    fun handleDelete(id: String) {
        state.realData.remove(id.toInt())
        state.data = buildJsonArray {
            state.realData.forEach {
                add(it.value)
            }
        }

        setState {
            entries.remove(id.toInt())
        }

        props.handleChange(ValueChangeEvent(props.id, state.data!!))
    }
}

fun RBuilder.jsonArray(schema: ConfigSchema, name: String, data: kotlinx.serialization.json.JsonArray?, id: String, handleChange: (event: ValueChangeEvent) -> Unit, level: Int) {
    child(JsonArray::class) {
        attrs {
            this.schema = schema
            this.name = name
            this.data = data
            this.id = id
            this.handleChange = handleChange
            this.level = level
        }
    }
}