package schema

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonPrimitive

@Serializable
data class ConfigSchema(
    val type: ConfigValueType,
    val description: List<String>? = null,
    val children: Map<String, ConfigSchema>? = null,
    val items: ConfigSchema? = null,
    val default: JsonPrimitive? = null,
    val constraints: ConfigValueConstraints = ConfigValueConstraints(),
)
