package components

import NameChangeEvent
import ValueChangeEvent
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.json.buildJsonObject
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.attrs
import schema.ConfigSchema
import styled.*
import kotlin.random.Random

external interface JsonMapProps: Props {
    var schema: ConfigSchema
    var name: String
    var data: kotlinx.serialization.json.JsonObject?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
    var level: Int
}

data class JsonMapState(
    var entries: MutableMap<String, ConfigSchema> = mutableMapOf(),
    var names: MutableMap<String, String> = mutableMapOf(),
    var data: kotlinx.serialization.json.JsonObject?,
    var showChildren: Boolean = true,
): State


class JsonMap(props: JsonMapProps): RComponent<JsonMapProps, JsonMapState>() {
    init {
        this.state = JsonMapState(
            data = props.data,
        )

        val random = Random(Random.nextInt())
        state.data = buildJsonObject {
            props.data?.forEach { entry ->
                val index = random.nextInt().toString()
                state.entries[index] = props.schema.items!!
                state.names[index] = entry.key
                put(index, entry.value)
            }
        }
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                borderColor = hsl((props.level * 40) % 256, 100, 15)
                +Styles.objectContainer
            }

            styledDiv {
                css {
                    +Styles.objectHeader
                }
                styledI {
                    attrs {
                        classes = if (state.showChildren) {
                            setOf("ri-arrow-down-s-line", "ri-xl")
                        } else {
                            setOf("ri-arrow-right-s-line", "ri-xl")
                        }

                        onClickFunction = {
                            setState {
                                showChildren = !showChildren
                            }
                        }
                    }
                }

                if (props.name != "") {
                    styledLabel {
                        css {
                            textTransform = TextTransform.capitalize
                            marginLeft = 10.px
                        }
                        +props.name
                    }
                }

                styledI {
                    css {
                        color = hex(0x4caf50)
                        marginLeft = 10.px
                        alignSelf = Align.center
                    }
                    attrs {
                        classes = setOf("ri-add-line", "ri-xl")
                        onClickFunction = {
                            val id = Random.nextInt().toString()
                            setState {
                                entries[id] = props.schema.items!!
                                names[id] = ""
                            }
                        }
                    }
                }
            }

            styledDiv {
                css {
                    if (!state.showChildren)
                        display = Display.none
                }

                descriptionBlock(props.schema.description)

                for (entry in state.entries) {
                    jsonMapEntry(
                        schema = entry.value,
                        key = state.names[entry.key] ?: "",
                        data = state.data?.get(entry.key),
                        id = entry.key,
                        handleChange = ::handleChange,
                        onNameChange = ::handleNameChange,
                        onDelete = ::handleDelete,
                        allowedKeys = props.schema.constraints.allowedKeys?.filter { it.intersect(state.names.values.toSet()).isEmpty() },
                        usePredefinedKeys = !props.schema.constraints.allowedKeys.isNullOrEmpty(),
                        level = props.level,
                    )
                }
            }
        }
    }

    fun handleChange(event: ValueChangeEvent) {
        state.data = buildJsonObject {
            if (state.data != null)
                for (entry in state.data!!) {
                    put(entry.key, entry.value)
                }

            put(event.id, event.value)
        }

        val newData = buildJsonObject {
            if (state.data != null) {
                for (entry in state.data!!) {
                    if (entry.key in state.names)
                        put(state.names[entry.key]!!, entry.value)
                }
            }
        }

        props.handleChange(ValueChangeEvent(props.id, newData))
    }

    fun handleNameChange(event: NameChangeEvent) {
        setState {
            names[event.id] = event.name
        }

        val newData = buildJsonObject {
            if (state.data != null) {
                for (entry in state.data!!) {
                    if (entry.key == event.id) {
                        put(event.name, entry.value)
                        continue
                    }
                    if (entry.key in state.names)
                        put(state.names[entry.key]!!, entry.value)
                }
            }
        }

        props.handleChange(ValueChangeEvent(props.id, newData))
    }

    fun handleDelete(id: String) {
        setState {
            data = buildJsonObject {
                if (state.data != null)
                    for (entry in state.data!!) {
                        if (entry.key == id)
                            continue

                        put(entry.key, entry.value)
                    }
            }
            names.remove(id)
            entries.remove(id)
        }

        val newData = buildJsonObject {
            if (state.data != null) {
                for (entry in state.data!!) {
                    if (entry.key == id) {
                        continue
                    }
                    if (entry.key in state.names)
                        put(state.names[entry.key]!!, entry.value)
                }
            }
        }

        props.handleChange(ValueChangeEvent(props.id, newData))
    }
}

fun RBuilder.jsonMap(schema: ConfigSchema, name: String, data: kotlinx.serialization.json.JsonObject?, id: String, handleChange: (event: ValueChangeEvent) -> Unit, level: Int) {
    child(JsonMap::class) {
        attrs {
            this.schema = schema
            this.name = name
            this.data = data
            this.id = id
            this.handleChange = handleChange
            this.level = level
        }
    }
}