package components

import ValueChangeEvent
import kotlinx.css.portal
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.doubleOrNull
import kotlinx.serialization.json.floatOrNull
import kotlinx.serialization.json.intOrNull
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.attrs
import schema.ConfigSchema
import schema.JsonType
import styled.css
import styled.styledDiv
import styled.styledInput
import styled.styledLabel

external interface JsonNumberProps: Props {
    var schema: ConfigSchema
    var name: String
    var data: JsonPrimitive?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
}

data class JsonNumberState(
    var value: Double = 0.0,
    var data: JsonPrimitive?,
): State


class JsonNumber(props: JsonNumberProps): RComponent<JsonNumberProps, JsonNumberState>() {
    init {
        val default = props.schema.default?.doubleOrNull ?: 0.0

        this.state = JsonNumberState(
            value = props.data?.content?.toDoubleOrNull() ?: default,
            data = props.data,
        )

        props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(state.value)))
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                +Styles.inputContainer
            }
            if (props.name != "") {
                styledLabel {
                    +props.name
                    attrs {
                        htmlFor = "${props.id}-input"
                    }
                }
            }

            styledInput {
                attrs {
                    id = "${props.id}-input"
                    type = InputType.number
                    value = state.value.toString()
                    onChangeFunction = {
                        val value = (it.target as HTMLInputElement).value.toDoubleOrNull()
                        if (value != null) {
                            setState {
                                this.value = value
                                this.data = JsonPrimitive(value)
                            }

                            props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(value)))
                        }
                    }
                }
            }
        }
    }

}

fun RBuilder.jsonNumber(schema: ConfigSchema, name: String, data: JsonPrimitive?, id: String, handleChange: (event: ValueChangeEvent) -> Unit) {
    child(JsonNumber::class) {
        attrs {
            this.schema = schema
            this.name = name
            this.data = data
            this.id = id
            this.handleChange = handleChange
        }
    }
}