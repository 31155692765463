package components

import ValueChangeEvent
import kotlinx.css.portal
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.booleanOrNull
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.attrs
import schema.ConfigSchema
import schema.JsonType
import styled.css
import styled.styledDiv
import styled.styledInput
import styled.styledLabel

external interface JsonBooleanProps: Props {
    var schema: ConfigSchema
    var name: String
    var data: JsonPrimitive?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
}

data class JsonBooleanState(
    var value: Boolean = false,
    var data: JsonPrimitive?,
): State


class JsonBoolean(props: JsonBooleanProps): RComponent<JsonBooleanProps, JsonBooleanState>() {
    init {
        val default = props.schema.default?.booleanOrNull ?: false
        this.state = JsonBooleanState(
            value = props.data?.booleanOrNull ?: default,
            data = props.data,
        )

        props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(state.value)))
    }

    override fun RBuilder.render() {
        styledDiv {
            styledInput {
                attrs {
                    id = "${props.id}-input"
                    type = InputType.checkBox
                    checked = state.value
                    onChangeFunction = {
                        val value = (it.target as HTMLInputElement).checked
                        setState {
                            this.value = value
                        }
                        props.handleChange(ValueChangeEvent(props.id, JsonPrimitive(value)))
                    }
                }
            }

            if (props.name != "") {
                styledLabel {
                    +props.name
                    attrs {
                        htmlFor = "${props.id}-input"
                    }
                }
            }
        }
    }

}

fun RBuilder.jsonBoolean(schema: ConfigSchema, name: String, data: JsonPrimitive?, id: String, handleChange: (event: ValueChangeEvent) -> Unit) {
    child(JsonBoolean::class) {
        attrs {
            this.schema = schema
            this.name = name
            this.data = data
            this.id = id
            this.handleChange = handleChange
        }
    }
}