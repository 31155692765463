package components

import ValueChangeEvent
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.url.URL
import org.w3c.dom.url.URLSearchParams
import org.w3c.files.Blob
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.XMLHttpRequest
import react.*
import react.dom.attrs
import schema.ConfigSchema
import styled.css
import styled.styledDiv
import styled.styledI
import styled.styledInput

external interface JsonSchemaRootProps: Props {
    var source: String
}

data class JsonSchemaRootState(
    var schema: ConfigSchema? = null,
    var data: JsonObject = JsonObject(mapOf()),
    var showJson: Boolean = false,
): State


class JsonSchemaRoot(props: JsonSchemaRootProps): RComponent<JsonSchemaRootProps, JsonSchemaRootState>() {
    init {
        this.state = JsonSchemaRootState()

        val json = Json {
            ignoreUnknownKeys = true
            prettyPrint = true
        }

        val xmlHttp = XMLHttpRequest()
        xmlHttp.open("GET", props.source)
        xmlHttp.onload = {
            val schema: ConfigSchema = json.decodeFromString(xmlHttp.responseText)
            setState {
                this.schema = schema
            }
        }

        xmlHttp.send()
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                backgroundColor = Color.black
                width = 100.pct
                fontSize = 2.rem
            }

            styledI {
                css {

                }
                attrs {
                    classes = setOf("ri-folder-open-line")
                }

                styledInput {
                    attrs {
                        type = InputType.file
                        onChangeFunction = {
                            val reader = FileReader()
                            reader.onloadend = {
                                setState {
                                    data = Json.decodeFromString(reader.result as String)
                                }
                            }
                            reader.readAsText((it.target as HTMLInputElement).files!![0]!!)
                        }
                    }
                }
            }
            styledI {
                css {

                }
                attrs {
                    classes = setOf("ri-save-3-line")
                    onClickFunction = {
                        setState {
                            showJson = !showJson
                        }
                        val encoded = Json.encodeToString(state.data)
                        console.log(encoded)
//                        //TODO: Send to the server and have to server respond with the data and a
//                        // Content-Disposition: attachment header. This should open a save dialog
////                       window.location.assign("data:application/json;charset=utf-8,$encoded")
//                        js("window.open(\"data:application/json;charset=utf-8,\" + encodeURI(encoded), \"_blank\")")
                    }
                }
            }
        }

        if (state.showJson) {
            styledDiv {
                +Json.encodeToString(state.data)
            }
        }

        if (state.schema != null)
            jsonSchema(state.schema!!, "", state.data, "", ::handleChange) {
                key = state.data.toString()
            }
    }

    fun handleChange(event: ValueChangeEvent) {
        state.data = event.value as JsonObject
    }
}

fun RBuilder.jsonSchemaRoot(source: String) {
    child(JsonSchemaRoot::class) {
        attrs {
            this.source = source
        }
    }
}