package components

import ValueChangeEvent
import kotlinx.serialization.json.*
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import schema.ConfigSchema
import schema.ConfigValueType

external interface ConfigSchemaComponentProps: Props {
    var schema: ConfigSchema
    var name: String
    var data: JsonElement?
    var id: String
    var handleChange: (event: ValueChangeEvent) -> Unit
    var level: Int
}

data class ConfigSchemaComponentState(
    var data: JsonElement?,
): State

class ConfigSchemaComponent(props: ConfigSchemaComponentProps): RComponent<ConfigSchemaComponentProps, ConfigSchemaComponentState>() {
    init {
        this.state = ConfigSchemaComponentState(
            data = props.data,
        )
    }

    override fun RBuilder.render() {
        when (props.schema.type) {
            ConfigValueType.STRING -> jsonString(props.schema, props.name, state.data as JsonPrimitive?, props.id, props.handleChange)
            ConfigValueType.BOOLEAN -> jsonBoolean(props.schema, props.name, state.data as JsonPrimitive?, props.id, props.handleChange)
            ConfigValueType.OBJECT -> jsonObject(props.schema, props.name, state.data as JsonObject?, props.id, props.handleChange, props.level)
            ConfigValueType.LIST -> jsonArray(props.schema, props.name, state.data as JsonArray?, props.id, props.handleChange, props.level)
            ConfigValueType.MAP -> jsonMap(props.schema, props.name, state.data as JsonObject?, props.id, props.handleChange, props.level)
            ConfigValueType.INTEGER -> jsonNumber(props.schema, props.name, state.data as JsonPrimitive?, props.id, props.handleChange)
            ConfigValueType.FLOAT -> jsonNumber(props.schema, props.name, state.data as JsonPrimitive?, props.id, props.handleChange)
        }
    }
}

fun RBuilder.jsonSchema(schema: ConfigSchema, name: String, data: JsonElement?, id: String, handleChange: (event: ValueChangeEvent) -> Unit, level: Int = 0, attributes: ConfigSchemaComponentProps.() -> Unit = {}) {
    child(ConfigSchemaComponent::class) {
        attrs {
            this.schema = schema
            this.name = name
            this.data = data
            this.id = id
            this.handleChange = handleChange
            this.level = level
            attributes(this)
        }
    }
}