import kotlinx.css.*
import kotlinx.css.properties.border
import styled.CssHolder
import styled.StyleSheet

object Styles : StyleSheet("Styles", isStatic = true) {
    val inputContainer by css {
        display = Display.inlineBlock
        position = Position.relative
        textAlign = TextAlign.left
        width = LinearDimension.fitContent
        paddingTop = 10.px
        margin(5.px, 10.px, 5.px, 10.px)

        input {
            display = Display.block
            border(2.px, BorderStyle.solid, Color.white, 5.px)
            padding(10.px)
            backgroundColor = rgb(21, 21, 21)
            color = Color.white
            fontWeight = FontWeight.normal

            focus {
                outline = Outline.none
            }
        }

        select {
            display = Display.block
            border(2.px, BorderStyle.solid, Color.white, 5.px)
            padding(10.px)
            backgroundColor = rgb(21, 21, 21)
            color = Color.white
            fontWeight = FontWeight.normal

            focus {
                outline = Outline.none
            }
        }

        label {
            position = Position.absolute
            textTransform = TextTransform.capitalize
            top = (-5).px
            left = 10.px
            padding(0.px, 2.px)
            backgroundColor = rgb(21, 21, 21)
            color = Color.white
        }

        focusWithin {
            color = hex(0xCD23F8)

            input {
                borderColor = hex(0xCD23F8)
            }

            select {
                borderColor = hex(0xCD23F8)
            }
        }
    }

    val jsonContainer by css {
        margin(10.px, 20.px, 10.px, 20.px)
    }

    val objectHeader by css {
        display = Display.flex
        alignContent = Align.center
        alignItems = Align.center
        justifyContent = JustifyContent.spaceBetween
        backgroundColor = rgb(32, 32, 32)
        padding(5.px)
    }

    val objectContainer by css {
        borderWidth = 2.px
        borderStyle = BorderStyle.solid
        margin(10.px)
    }

    val descriptionContainer by css {
        display = Display.flex
        margin(10.px)
        backgroundColor = rgb(6, 25, 80)
    }

    val descriptionIconContainer by css {
        display = Display.inlineFlex
        alignItems = Align.center
        alignContent = Align.center
        backgroundColor = hex(0x114683)
        boxSizing = BoxSizing.borderBox
        padding(15.px)
    }

    val descriptionText by css {
        display = Display.inlineBlock
        padding(10.px)
        alignSelf = Align.center
    }

    val descriptionLine by css {
        margin(0.px)
    }
} 
