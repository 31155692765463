package schema

import kotlinx.serialization.Serializable

@Serializable
data class ConfigValueConstraints(
    val enum: List<String>? = null,
    val allowedKeys: List<List<String>>? = null,
    val maxLength: Int? = null,
    val maxValueInt: Long? = null,
    val minValueInt: Long? = null,
    val maxValueFloat: Double? = null,
    val minValueFloat: Double? = null,
)