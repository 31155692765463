import components.jsonSchemaRoot
import react.dom.render
import kotlinx.browser.document
import kotlinx.browser.window

fun main() {
    //TODO:
    // - Parse and render description
    // - Check constraints?
    // - Defaults?
    // - Make things look nice


    window.onload = {
        render(document.getElementById("root")) {
            jsonSchemaRoot("/arena.schema.json")
//            child(SchemaPart::class) {
//                attrs {
//                    source = "/arena.schema.json"
//                    name = "ROOT"
//                    required = true
//                }
//            }
        }
    }
}
